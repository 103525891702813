@use "base";

@media (max-width: base.$screen-sm) {
  button {
    width: 100%;
  }

  h1 {
    font-size: 18px;
  }

  .mb-4 {
    margin-bottom: 1rem !important;
  }

  .ant-table-cell {
    font-size: base.$font-size-sm;
  }

  .ant-table-tbody > tr > td {
    padding: 4px !important;
  }

  .show-on-sm {
    display: block !important;
  }

  .hide-on-sm {
    display: none !important;
  }

  .d-flex-to-grid-on-sm {
    display: grid !important;
  }

  .fs-17 {
    font-size: 16px !important;
  }

  label {
    margin-bottom: 1px;
  }

  .home-item img {
    width: 40px;
  }

  .mb-3 {
    margin-bottom: 0.5rem !important;
  }

  .p-4 {
    padding: 1.2rem !important;
  }

  .mt-4 {
    margin-top: 1.2rem !important;
  }

  .dashboard-content {
    padding: 1.4rem !important;
  }

  a[nz-tab-link] {
    font-size: 15px;
  }
}
