@use "base";

/* ==============================================================
Tab
===============================================================*/
.ant-tabs-ink-bar {
  background-color: base.$primary;
}

.ant-tabs-tab-active span {
  color: base.$primary;
}

/* ==============================================================
Button
===============================================================*/
.ant-btn {
  font-size: base.$btn-font-size;

  &:focus, &:hover {
    border-color: base.$gray;
  }
}

button[modalCloser] {
  &:focus, &:hover {
    border-color: base.$primary;
  }
}

button[nzType='primary'] i {
  color: white;
}

.ant-btn-primary,
.ant-btn-primary:focus,
.ant-btn-primary:hover,
.ant-btn-primary:active {
  background-color: base.$primary;
  border-color: base.$primary;
}

.ant-btn-primary:not([disabled]) > span {
  color: white;
}

.ant-btn-text, .ant-btn-text span {
  border-color: base.$primary;
  color: base.$primary;
}

/* ==============================================================
Input
===============================================================*/
.ant-input, .ant-input-number {
  background-color: base.$input-bg;
  border: 0.5px solid base.$input-border-color;
  border-radius: base.$border-radius;
  color: base.$font-color;
}

.ant-input-number-handler-wrap {
  border-radius: base.$border-radius;
}

.ant-input-lg {
  font-size: base.$input-font-size;
  padding: 8.1px 11px;
}

.ant-input:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: base.$primary;
}

.ant-input-number:hover {
  border-color: base.$primary;
}

.ant-input-number-handler-down {
  border-bottom-right-radius: base.$border-radius;
}

.ant-input-number-handler-up {
  border-top-right-radius: base.$border-radius;
}

.ant-input-number-handler:hover .ant-input-number-handler-down-inner, .ant-input-number-handler:hover .ant-input-number-handler-up-inner {
  color: base.$primary;
}

.ant-input-focused,
.ant-input:focus,
.ant-input-number:focus,
.ant-input-affix-wrapper-focused,
.ant-input-number-focused,
.ant-input-affix-wrapper:focus {
  border-color: base.$primary;
  box-shadow: none;
}

nz-input-group, .ant-input-affix-wrapper-focused {
  &:hover i, i {
    color: base.$primary;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: rgba(base.$primary, 0.4);
}

nz-select-placeholder {
  font-size: base.$placeholder-font-size;
}

.ant-select,
.ant-input-number,
nz-date-picker {
  width: 100%;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--abu);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: base.$primary;
  box-shadow: none;
}

nz-select-top-control, nz-option-item {
  color: base.$font-color;
}

/* ==============================================================
Message and Alert
===============================================================*/
.ant-alert,
.ant-message-notice-content {
  padding: 10px 18px;
}

/* ==============================================================
Checkbox
===============================================================*/
.ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner {
  border-color: base.$primary;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: base.$primary;
  border-color: base.$primary;
}

.ant-checkbox-checked::after {
  border-color: base.$primary;
}

/* ==============================================================
Date Picker
===============================================================*/
.ant-picker {
  border-radius: base.$border-radius;
}

.ant-picker-input > input {
  color: base.$font-color;
}

/* ==============================================================
Steps
===============================================================*/
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: base.$primary;
}

.ant-steps-item-process .ant-steps-item-icon, .ant-steps-item-finish .ant-steps-item-icon {
  border-color: base.$primary;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon,
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: base.$primary;
}

/* ==============================================================
Mix
===============================================================*/
.ant-input-group-addon,
.ant-btn-primary[disabled] {
  color: base.$font-color;
}

.ant-input-affix-wrapper,
.ant-modal-content,
button[nz-button],
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-message-notice-content,
.ant-alert {
  border-radius: base.$border-radius;
}

.ant-spin-dot-item {
  background-color: base.$primary;
}

.ant-picker-suffix .anticon-calendar, .ant-picker-today-btn, .ant-picker-today-btn:hover {
  color: base.$primary;
}

.ant-picker-focused, .ant-picker:hover {
  border-color: base.$primary;
  box-shadow: none;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: base.$primary;
}

.ant-picker-cell-in-view {
  color: base.$font-color;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: base.$primary;
}

nz-layout {
  nz-sider, nz-layout {
    max-height: 100vh;
    overflow-y: auto;
  }

  nz-sider::-webkit-scrollbar {
    display: none !important;
  }
}

.ant-list-item:not(.ant-list-bordered) {
  padding: 10px 0;
}

.ant-switch {
  background-color: #EA5455;
}

.ant-switch-checked {
  background-color: #3AC358;
}
