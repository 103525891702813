@use "base";

// For loading.css file
:root {
  --primary: #{base.$primary}
}

@import "~ng-zorro-antd/ng-zorro-antd.min.css";

@import "ng-zorro";
@import "fonts";
@import "global";
@import "responsive";
@import "utils";
