@use 'base';

body {
  font-family: base.$font-family;
  font-size: base.$font-size;
  color: base.$font-color;
}

*[routerLink] {
  cursor: pointer;
}

hr {
  border: thin dotted #EEEEEE;
  border-bottom: none;
}

ul {
  padding: 0;
  list-style: none;
  margin-bottom: 0;
}

a {
  color: base.$primary;

  &:hover {
    color: base.$primary;
  }
}

label {
  display: inline-block;
  margin-bottom: 4px;
  color: base.$font-color;
  @extend .font-weight-500;
}

h1, h2, h3, span, td, th, i[nz-icon], .ant-table-footer, .ant-table-thead > tr > th {
  color: base.$font-color;
}

h1, h2, h3 {
  margin-bottom: 0;
}

h1 {
  @extend .font-weight-500;
  font-size: 24px;
}

input::-webkit-input-placeholder {
  font-size: base.$placeholder-font-size;
}

button[nzType=text] {
  &:hover, &:focus {
    i {
      color: base.$primary;
    }
  }
}

button, input, [nz-tab-link], thead tr th.ant-table-cell {
  @extend .font-weight-500;
}

button, button span {
  font-weight: 500;
}

thead tr th.ant-table-cell {
  @extend .font-weight-600, .fs-15;
}

input[readonly] {
  cursor: default;
}

.text-gray {
  color: base.$gray;
}

.primary-bg {
  height: 100%;
  background-image: url('../images/primary-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.c-danger {
  color: red;
}

%badge {
  color: white;
  font-size: 80%;
  font-weight: bold;
  padding: 0.6em 1em;;
  border-radius: 30px;
  cursor: default;
}

.badge-success {
  @extend %badge;

  background-color: #E7F6ED;
  color: #3AC358;
}

.badge-danger {
  @extend %badge;

  background-color: #FFB4B4;
  color: #EA5455;
}

.btn-transparent {
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
  box-shadow: none;
}

td > a:hover {
  text-decoration: underline;
}
