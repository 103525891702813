$primary: #8B0037;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;

$gray: #ADB8BE;

$border-radius: 8px;
$bg-color: $primary;
$font-family: 'Montserrat', sans-serif;
$font-size: 14px;
$font-size-sm: 80%;
$font-color: #4F586F;
$input-bg: white;
$input-font-size: $font-size;
$input-border-color: #E0E0E0;
$placeholder-font-size: 13px;
$btn-font-size: 13px;

$screen-sm: 768px;
