@use 'base';

.break-word {
  word-break: break-word;
}

.show-on-sm {
  display: none;
}

.hide-on-sm {
  display: block;
}

.d-flex-to-grid-on-sm {
  display: flex;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.v-align-bottom {
  vertical-align: bottom;
}

.v-align-top {
  vertical-align: top;
}

.c-primary {
  color: base.$primary !important;
}

.c-white {
  color: white;

  &:hover {
    color: white;
  }
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.fs-20 {
  font-size: 20px;
}

.fs-18 {
  font-size: 18px;
}

.fs-17 {
  font-size: 17px;
}

.fs-16 {
  font-size: 16px;
}

.fs-15 {
  font-size: 15px;
}

.fs-13 {
  font-size: 13px;
}

.cursor-pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 1rem;
}

.mr-3 {
  margin-right: 1rem;
}

.mb-3 {
  margin-bottom: 1rem;
}

.ml-3 {
  margin-left: 1rem;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mr-4 {
  margin-right: 1.5rem;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.ml-4 {
  margin-left: 1.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.mr-5 {
  margin-right: 3rem;
}

.mb-5 {
  margin-bottom: 3rem;
}

.p-0 {
  padding: 0;
}

.py-0 {
  padding-top: 0;
}

.py-0 {
  padding-bottom: 0;
}

.px-2 {
  padding-right: 0.5rem;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.p-4 {
  padding: 1.5rem;
}

.px-4 {
  padding-right: 1.5rem;
}

.px-4 {
  padding-left: 1.5rem;
}

.pt-5 {
  padding-top: 3rem;
}

.pr-5 {
  padding-right: 3rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-5 {
  padding-bottom: 3rem;
}

.pl-5 {
  padding-left: 3rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.max-w-300 {
  max-width: 300px;
}

.max-w-350 {
  max-width: 350px;
}

.max-w-500 {
  max-width: 500px;
}

.w-300 {
  width: 300px;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
